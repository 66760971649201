@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .main-screen {
      @apply py-2 px-4 md:px-20 md:py-5
    } 
    .input {
      @apply p-2 text-sm border border-transparent bg-transparent focus:border-transparent
    }
    .option {
      @apply w-full p-2

    }
    .country-info {
      @apply text-xs inline-flex gap-2 
    }
}